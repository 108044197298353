<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>จัดการลูกค้า</b></h3>
        <small>    
          <span class="text-primary" @click="$router.push(`/setting/customer/warranty/company?oid=${$route.query.oid}`)" style="cursor: pointer;">บริษัท</span>   >           
          <b>{{ mode == 'create' ? 'เพิ่มบริษัท' :'แก้ไขบริษัท' }}</b>
          </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">       
        <b-button
          v-if="customerPermission=='create' || customerPermission=='edit'"
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
          :disabled="isLoading"
        >
          <feather-icon icon="SaveIcon" />
          บันทึก
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push(`/setting/customer/warranty/company?oid=${$route.query.oid}`)"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-row >
            <b-col><h2><b>{{ mode == 'create' ? 'เพิ่มบริษัท' :'แก้ไขบริษัท' }}</b></h2></b-col>
          </b-row>

          <!--- START (ชื่อบริษัท) --->
          <b-row class="mt-2">
            <b-col md="3" class="">
              <label>ชื่อบริษัท <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="Vserv"
                size="sm"
                value="Vserv"
                v-model="companyData.companyName"
              />
            </b-col>
       
            <b-col md="2" class="">
              <label>สถานะ</label>
              <b-form-group>
                <b-form-radio-group
                  v-model="companyData.status"
                  :options="statusOptions"
                  name="radio-inline"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--- END (ชื่อบริษัท) --->

          <!--- START (เบอร์โทร) --->
          <b-row>
            <b-col md="3" class="">
              <label>เบอร์โทร</label>
              <b-form-input
                placeholder="0987654321"
                size="sm"
                value="0987654321"
                v-model="companyData.tel"
              />
            </b-col>
       
            <b-col md="3" class="">
              <label>Email</label>
              <b-form-input
                v-model="companyData.email"
                placeholder="admin@gmail.com"
                size="sm"
                value="admin@gmail.com"
              />
            </b-col>
          </b-row>
          <!--- END (เบอร์โทร) --->


          <!--- START ROW รายละเอียด --->
          <b-row class="mt-1">
            <b-col md="12">
              <label>รายละเอียด</label>
              <b-form-textarea
                v-model="companyData.detail"
                id="textarea-default"
                placeholder="รายละเอียด"
                rows="2"
                size="sm"
              />
            </b-col>
          </b-row>
          <!--- END รายละเอียด --->
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BForm,
  BButton,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import CustomerService from "@/services/setting/customer";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BForm,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadioGroup,
  },
  data() {
    return {
      statusOptions: [
        { text: 'เปิด', value: 1 },
        { text: 'ปิด', value: 0 },        
      ],
      companyData:{       
        companyName: "",
        detail: "",
        tel: "",
        email: "",
        status: 1     
      },
      isLoading:false
    };
  },

  computed:{
    customerPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003-1")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    mode(){
      const companyId = this.$route.query.cid
      if(companyId){
        return "edit"
      }
      return "create"
    },
    paramCompanyId(){
      return this.$route.query.cid
    },
    paramOwnerId(){
      return this.$route.query.oid
    }
  },


  methods: { 
    async save(){      
      if(!this.companyData.companyName){
        alert("กรุณากรอกชื่อบริษัท")
        return
      }

      if(this.mode == 'create'){
        await this.insert()
      }else{
        await this.update()
      }
    }, 

    async getCustomerCompanyDetail(){
      const result = await CustomerService.GetCustomerCompanyById(this.paramCompanyId)
      this.companyData = result.data
    },
    async insert(){
      this.isLoading =true
      const result = await CustomerService.InsertCustomerCompany({ ...this.companyData, ownerId:this.paramOwnerId})
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Company`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มรายการเรียบร้อย`,
            },
          });
          this.$router.push(`/setting/customer/warranty/company?oid=${this.$route.query.oid}`)             
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Company`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการเพิ่มรายการ`,
            },
          });
        }

      this.isLoading = false
      
    },
    async update(){ 
      this.isLoading =true
      const result = await CustomerService.UpdateCustomerCompany(this.companyData,this.paramCompanyId )
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Company`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขรายการเรียบร้อย`,
            },
          });
          this.$router.push(`/setting/customer/warranty/company?oid=${this.$route.query.oid}`)                    
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Company`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการแก้ไขรายการ`,
            },
          });
        }
        this.isLoading = false
    }
  },

  mounted(){
    if(this.mode == "edit"){
      this.getCustomerCompanyDetail()
    }    
  }
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
